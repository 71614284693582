<template>
  <hk-product
    :data="products"
    @detail="goDetail"
    @purchase="handlePurchase"
  />
</template>

<script>
import HkProduct from './swiper'
export default {
  name: 'HkSeal',
  components: {
    HkProduct
  },
  data() {
    this.getCategoryProduct(2); 
    return {
      exportData: [],
    }
  },
  computed: {
    products () {
      return this.exportData
    }
  },
  methods: {
    goDetail (id) {
      this.$router.push({
        name: 'detail',
        query: { id }
      })
    },
    handlePurchase (id) {
      //
    },
    getCategoryProduct (cat_id) {
      let self = this;
      let category_id = cat_id
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/product/showSeries?category_id=' + category_id;
      this.axios.get(apiCall)
      .then(function (response) {
        self.exportData = self.exportData.concat(response.data.data.list);

      })
      .catch(function (error) {
        console.log(error);
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.hk-product__wrapper::v-deep {
  .hk-swiper .hk-product.hk-product_active .hk-card {
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>
